<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        类型: <el-select v-model="search.type">
        <el-option :value=0 label="全部">全部</el-option>
        <el-option :value=1 label="安卓">安卓</el-option>
        <el-option :value=2 label="IOS">IOS</el-option>
      </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">刷新</el-button>
        <el-button type="primary" size="medium" @click="hotfixList()">热更管理</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getTypeTitle(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="线路" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="line" label="地址" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showEditDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog title="编辑" v-model="dialogEdit" width="600px" top="10vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >类型</el-col>
        <el-col :span="20">
          <el-select multiple v-model="editform.types">
            <el-option value="1" label="安卓"></el-option>
            <el-option value="2" label="IOS"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >线路</el-col>
        <el-col :span="20"><el-input v-model="editform.title" placeholder="请输入线路标题"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">地址</el-col>
        <el-col :span="20"><el-input v-model="editform.line" placeholder="请输入地址"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">权重</el-col>
        <el-col :span="20"><el-input type="number" v-model="editform.weight" placeholder="请输入权重"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="save">修改</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>

    <!-- 添加修改 -->
    <el-dialog :title="dialogTitle" v-model="dialog" width="600px" top="10vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >类型</el-col>
        <el-col :span="20">
          <el-select multiple v-model="form.types">
            <el-option value="1" label="安卓"></el-option>
            <el-option value="2" label="IOS"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >线路</el-col>
        <el-col :span="20"><el-input v-model="form.title" placeholder="请输入线路标题"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">地址</el-col>
        <el-col :span="20"><el-input v-model="form.line" placeholder="请输入地址"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">权重</el-col>
        <el-col :span="20"><el-input type="number" v-model="form.weight" placeholder="请输入权重"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="add">添加</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="热更管理(最多显示10条)" v-model="dialogHotfix" width="1000px" top="10vh" :close-on-click-modal="false">
      <div class="search-box">
        <el-button type="primary" size="medium" @click="hotfixVersionAdd()">发布版本</el-button>
        <el-button type="primary" size="medium" @click="hotfixList()">刷新</el-button>
      </div>
      <el-table :data="hotfixData" :header-cell-style="{background:'#F7F8FA'}">
        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="version" label="版本" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="addtime" label="发布时间" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="path" label="版本地址" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="url" label="URL" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="api" label="API地址" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" width="250px" align="center">
          <template #default="scope">
            <span class="operation" @click="showHotfixVersion(scope.row)">版本详情</span>
            <span class="operation" @click="publishHotfixVersion(scope.row)">重新发布</span>
            <span class="operation" @click="delHotfixVersion(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="发布热更" v-model="dialogHotfixAdd" width="600px" top="10vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >版本</el-col>
        <el-col :span="20"><el-input v-model="hotfixForm.version" placeholder="请输入热更版本"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :http-request="uploadWgt"
              accept="*"
              :before-upload="beforeZipUpload">
            <el-button type="primary" size="small">选择文件</el-button>
          </el-upload>
        </el-col>
        <el-col :span="20">
          <el-progress :text-inside="true" :stroke-width="18" :percentage="filePercentage"></el-progress>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="addHotifx">发布</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="版本详情" v-model="dialogHotfixVersion" width="1000px" top="10vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >版本</el-col>
        <el-col :span="20"><el-input v-model="hotfixFormShow.version" disabled ></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >版本地址</el-col>
        <el-col :span="20"><el-input type="textarea" rows="5" v-model="hotfixFormShow.path" ></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >热更包URL</el-col>
        <el-col :span="20"><el-input type="textarea" rows="5"  v-model="hotfixFormShow.url"  ></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >API地址</el-col>
        <el-col :span="20"><el-input type="textarea" rows="5"  v-model="hotfixFormShow.api" ></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="dialogHotfixVersion=false">关闭</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
import httpClient from "@/config/httpClient";

export default {
  name: "线路管理",
  data() {
    return {
      tableData: [], //数据
      hotfixData: [], //数据
      search:{
        type:0,
      },
      hotfixFormShow:{

      },
      filePercentage:0,
      dialogHotfixAdd:false,
      dialogHotfixVersion:false,
      hotfixForm:{
        version:"",
        hash:""
      },
      dialogLine:false,
      dialogHotfix:false,
      lineForm:{
        line:"",
      },
      dialog:false,
      dialogEdit:false,
      dialogTitle:"添加",
      editform: {
        types:[],
        type_id:"",
        title:"",
        line:"",
        weight:0,
        id:0,
      },
      form: {
        types:[],
        type_id:"",
        title:"",
        weight:0,
        line:"",
      }, // 表单数据
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    showHotfixVersion(row){
      this.hotfixFormShow = row
      this.dialogHotfixVersion = true
    },
    publishHotfixVersion(row){
      this.$common.showLoading()
      httpClient("hotfixVersionPublish").post({id:row.id,}).then((res)=>{
        this.$common.hideLoading()
        if (res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.hotfixList()
          this.$message({message:"发布成功",type:"success"})
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    delHotfixVersion(row){
      this.$confirm("删除热更包: " + row.version + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()
        httpClient("hotfixVersionDel").post({id:row.id,}).then((res)=>{
          this.$common.hideLoading()
          if (res.code == 0){
            this.hotfixList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          console.log(err)
          this.$common.hideLoading()
        })
      })
    },
    addHotifx(){
      this.$common.showLoading()
      httpClient("hotfixVersionAdd").post(this.hotfixForm).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialogHotfixAdd = false
          this.hotfixList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    beforeZipUpload(file){
      console.log(file)
      if (this.hotfixForm.version == ""){
        // this.hotfixForm.version = file.name
      }
    },
    uploadWgt(param){
      console.log(param)
      if(param.file.name.indexOf(".wgt") <= 0){
        this.$message({message:"只能上传wgt文件",type:"error"})
        return
      }
      this.filePercentage = 0
      if (param.file.size > 1024 * this.per_slice){
        // 不能超过1G
        this.$message({message:"上传的压缩包不可以超过1G",type:"error"})
        return
      }
      httpClient().setUploadUrl("").uploadSlice(param.file, (percentage)=>{
        this.filePercentage = percentage
      },(res)=>{
        console.log(res)
        this.hotfixForm.hash = res.hash
        this.filePercentage = 100
        // 服务器解压，然后导入数据库
      },(err)=>{
        console.log(err)
      })
    },
    hotfixVersionAdd(){
      this.hotfixForm = {
        version: "",
        url: "",
      }
      this.dialogHotfixAdd = true

    },
    getTypeTitle(row){
      if(row.type){
        var title = ""
        for(var i in row.type){
          if (row.type[i] == 1){
            title += "安卓,"
          }else if (row.type[i] == 2){
            title += "IOS,"
          }
        }
        return title
      }else{
        return "全部"
      }
    },
    hotfixList(){
      this.$common.showLoading()
      httpClient("hotfixVersionList").post().then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialogHotfix = true
          this.hotfixData = res.data.list
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getList(){
      this.$common.showLoading()
      httpClient("lineList").post({type:this.search.type}).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.tableData = res.data.list
        }
      })
    },
    showEditDialog(row){
      this.dialogEdit = true
      this.editform = {
        id:row.id,
        weight:row.weight,
        types:row.type,
        title:row.title,
        line:row.line,
      }
    },
    save(){
      this.$common.showLoading()
      this.editform.type_id = this.editform.types.join(",")
      httpClient("lineEdit").post(this.editform).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialogEdit = false
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    showDialog(){
      this.dialog = true
      this.form = {
        types:[], // 默认就是0
        type_id:"",
        title:"",
        weight:0,
        line:"",
      }
    },
    add(){
      this.$common.showLoading()
      this.form.type_id = this.form.types.join(",")
      httpClient("lineAdd").post(this.form).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialog = false
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    del(row){

      this.$confirm("删除线路: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        this.$common.showLoading()

        httpClient("lineDel").post({id:row.id,}).then((res)=>{
          this.$common.hideLoading()

          if (res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        },(err)=>{
          console.log(err)
          this.$common.hideLoading()
        })
      })
    }
  },
};
</script>
